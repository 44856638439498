var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {rules: [{ required: true, message: _vm.$t('请输入用户名') }, { validator: _vm.handleUsernameOrEmail }], validateTrigger: 'change'}
        ]),expression:"[\n          'username',\n          {rules: [{ required: true, message: $t('请输入用户名') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}\n        ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入用户名"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {rules: [{ required: true, message: _vm.$t('请输入密码') }], validateTrigger: 'blur'}
        ]),expression:"[\n          'password',\n          {rules: [{ required: true, message: $t('请输入密码') }], validateTrigger: 'blur'}\n        ]"}],attrs:{"size":"large","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {rules: [{ required: true, message: '请输入验证码' }, { validator: _vm.handleUsernameOrEmail }], validateTrigger: 'change'}
        ]),expression:"[\n          'code',\n          {rules: [{ required: true, message: '请输入验证码' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}\n        ]"}],staticStyle:{"width":"63%"},attrs:{"size":"large","type":"text","placeholder":"请输入验证码"}}),_c('div',{staticClass:"login-code"},[_c('img',{attrs:{"src":_vm.codeUrl,"alt":"验证码"},on:{"click":_vm.getCode}})])],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v(" 登录 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }