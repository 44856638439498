<template>
  <div class="main">
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          size="large"
          type="text"
          placeholder="请输入用户名"
          v-decorator="[
            'username',
            {rules: [{ required: true, message: $t('请输入用户名') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          size="large"
          placeholder="请输入密码"
          v-decorator="[
            'password',
            {rules: [{ required: true, message: $t('请输入密码') }], validateTrigger: 'blur'}
          ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input
          size="large"
          type="text"
          placeholder="请输入验证码"
          style="width: 63%"
          v-decorator="[
            'code',
            {rules: [{ required: true, message: '请输入验证码' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
          ]"
        />
        <div class="login-code">
          <img :src="codeUrl" alt="验证码" @click="getCode" />
        </div>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        > 登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import md5 from 'md5'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getCodeImg } from '@/api/login'

export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false
      },
      loginForm: {
        uuid: null
      },
      codeUrl: null,
    }
  },
  created () {
    this.getCode()
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // 验证码
    getCode () {
      getCodeImg().then(res => {
        if (res.code === 200) {
          this.codeUrl = 'data:image/gif;base64,' + res.data['img']
          this.loginForm.uuid = res.data['uuid']
          this.form.setFieldsValue({
            code: null
          })
        }
      })
    },
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },

    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        Login,
        state
      } = this

      validateFields((err, values) => {
        if (!err) {
          state.loginBtn = true
          const loginParams = { ...values }
          loginParams.uuid = this.loginForm.uuid
          Login(loginParams).then(res => {
            res === 200 && this.loginSuccess()
          }).finally(e => {
            state.loginBtn = false
          })
        }
      })
    },

    loginSuccess (res) {
      this.$router.push({ name: 'index' }, () => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      })

      this.isLoginError = false
    },
    handleChange () {

    }

  }
}
</script>

<style lang='less' scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}

.login-code {
  width: 112px;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
